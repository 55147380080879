// Here you can add other styles
@media (min-width: 240px) {
  .webcam {
    &-responsive {
      width: 340px;
      height: 280px;
    }
  }
  .sigCanvas {
    width: 300px;
  }
}

@media (min-width: 576px) {
  .webcam {
    &-responsive {
      width: 360px;
      height: 300px;
    }
  }
  .sigCanvas {
    width: 340px;
  }
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .webcam {
    &-responsive {
      width: 580px;
      height: 440px;
    }
  }
  .sigCanvas {
    width: 650px;
  }
}
@media (min-width: 1200px) {
  .webcam {
    &-responsive {
      width: 640px;
      height: 500px;
    }
  }
}

.c {
  &-active {
    background-color: $purple !important;
  }
  &-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-header {
    &-toggler {
      &-icon {
        height: 1.7rem;
      }
    }
  }
  &-sidebar {
    &-nav {
      margin-top: 1rem;
      &-dropdown {
        &-toggle {
          color: $info !important;
          &:hover {
            color: $purple !important;
          }
          &:after {
            height: 2rem;
          }
        }
        &-item {
          background-color: $purple !important;
        }
      }
      &-link {
        color: $white !important;
        border-radius: 1rem;
        margin-left: 5%;
        margin-right: 5%;
        &:hover {
          color: $primary !important;
          background-color: $white !important;
          font-family: $Black;
          .c-sidebar-nav-icon {
            color: $primary !important;
          }
        }
      }
      &-icon {
        color: $white !important;
        height: 1.5rem;
      }
    }
    &-brand {
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border: solid;
      border-bottom-width: 0.7rem;
      border-top-width: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: $purple;
    }
    &-minimized {
      .c-sidebar-brand {
        border: none;
      }
    }
  }
}

.number-notifications {
  position: absolute;
  z-index: 1;
  background-color: $danger;
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
  color: $white;
  border-radius: 100%;
  left: 55%;
  bottom: 60%;
  text-align: center;
  font-family: $Bold;
  border: 3px solid $white;
  &-menu-vertical {
    bottom: 25%;
    left: 85%;
    border: 2px solid $white;
  }
}

ul li {
  font-size: medium !important;
  &:hover {
    color: $white !important;
  }
}

.line {
  &-vertical {
    width: 2px;
    max-height: 80px;
    background-color: $primary;
    margin-left: 10px;
    margin-right: 10px;
  }
  &-horizontal {
    border-bottom: 1px solid $gray;
  }
}

.dropdown {
  &-scroll {
    max-height: 180px;
    overflow-x: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $gray;
      &-thumb {
        background-color: $blue;
        border-radius: 5px;
      }
    }
  }
}

.input {
  &-valid {
    border-color: none !important;
  }
  &-notvalid {
    border-color: #ff0000 !important;
  }
}

.form {
  &-control {
    &:hover {
      border-color: $primary;
      box-shadow: 0 0 0 0.2rem rgba(48, 0, 74, 0.25);
      outline: 0;
    }
  }
}

.classMap {
  height: 700px;
  width: 1000px;
}

.rounded {
  &-lg {
    border-radius: 0.7rem !important;
    &-top {
      border-top-right-radius: 0.7rem;
      border-top-left-radius: 0.7rem;
    }
    &-bottom {
      &-left {
        border-bottom-left-radius: 0.7rem;
      }
      &-right {
        border-bottom-right-radius: 0.7rem;
      }
    }
    &-right {
      border-top-right-radius: 0.7rem;
      border-bottom-right-radius: 0.7rem;
    }
    &-left {
      border-top-left-radius: 0.7rem !important;
      border-bottom-left-radius: 0.7rem !important;
    }
  }
}

.card {
  border-radius: 0.6rem;
  background-color: rgba($color: $white, $alpha: 0.95);
  border-color: transparent;
  &-header {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important;
  }
}

.table {
  &-sm {
    background-color: $gray !important;
    border: 0px solid none;
    th {
      padding: 0;
    }
  }
  thead {
    tr {
      background: $info;
      color: $white;
    }
    th {
      border: 0px solid;
    }
  }
  td {
    border-top: 0;
  }
  &-responsive {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    &::-webkit-scrollbar {
      height: 6px;
      background-color: $gray;
      &-thumb {
        background-color: $blue;
        border-radius: 5px;
      }
    }
  }
}

.main-notifications {
  position: fixed;
  max-width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba($color: $dark, $alpha: 0.1);
  &-container {
    position: fixed;
    min-width: 260px;
    background-color: $white;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 85px;
    &-scroll {
      height: 100vh;
      overflow-x: none;
      overflow-y: auto;
      padding-bottom: 11rem;
      padding-top: 1rem;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: $gray;
        &-thumb {
          background-color: $blue;
          border-radius: 3px;
        }
      }
    }
  }
}
