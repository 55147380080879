// fonts
$Black: Lexend-Black;
$ExtraLight: Lexend-ExtraLight;
$SemiBold: Lexend-SemiBold;
$Bold: Lexend-Bold;
$Medium: Lexend-Medium;
$Regular: Lexend-Regular;

// Variables colors overrides
$primary: #30004a;
$secondary: #ebe4e4;
$success: #4c8b6a;
$info: #3c4b64;
$warning: #ecc95f;
$danger: #bb2865;
// Color variable reservada no aplica cambios
$color: #08adad;
$dark: #474a55;
$light: #eeeeee;
$white: #ffffff;
$orange: #b96403;
$purple: #5e039b;
$blue: #4ea5ec;
$gray: #d0d2d3;

// Gradients
$linear-gradient-primary: linear-gradient(
  90deg,
  rgb(69, 2, 105) 6%,
  rgb(85, 4, 139) 47%,
  rgba(102, 7, 165, 0.914) 82%
);

$linear-gradient-danger: linear-gradient(
  90deg,
  rgba(185, 61, 93, 1) 0%,
  rgba(164, 72, 96, 1) 35%,
  rgba(182, 122, 138, 1) 100%
);

$linear-gradient-success: linear-gradient(
  90deg,
  rgba(65, 129, 96, 1) 0%,
  rgba(97, 161, 127, 1) 35%,
  rgba(111, 170, 139, 1) 100%
);

$linear-gradient-color: linear-gradient(
  90deg,
  rgb(24, 136, 136) 0%,
  rgb(23, 171, 171) 35%,
  rgb(26, 203, 203) 100%
);

$linear-gradient-blue: linear-gradient(
  90deg,
  rgba(48, 0, 74, 1) 23%,
  rgba(0, 108, 199, 1) 76%,
  rgba(0, 163, 204, 0.5945728633250176) 100%
);

$linear-gradient-info: linear-gradient(
  90deg,
  rgba(58, 141, 209, 1) 23%,
  rgba(78, 165, 236, 1) 76%,
  rgba(113, 176, 228, 1) 100%
);

$linear-gradient-dark: linear-gradient(
  90deg,
  rgb(89, 90, 91) 23%,
  rgb(135, 139, 142) 76%,
  rgb(153, 157, 160) 100%
);


// News Gradients
$linear-gradient-follow: linear-gradient(
  90deg,
  rgba(6, 146, 153, 1) 0%,
  rgba(12, 166, 173, 1) 57%,
  rgba(0, 202, 212, 1) 100%
);

$linear-gradient-accompaniments: linear-gradient(
  90deg,
  rgba(26, 110, 181, 1) 0%,
  rgba(42, 114, 176, 1) 57%,
  rgba(68, 118, 159, 1) 100%
);

$linear-gradient-modal: linear-gradient(90deg, rgba(0,0,0,0.5049879570969013) 0%, rgba(221,220,218,0.667452943091299) 50%, rgba(0,0,0,0.502186836648722) 100%);
