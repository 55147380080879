// Extra-Light
@font-face {
  font-family: $ExtraLight;
  src: local($ExtraLight),
    url("../assets/fonts/" + $ExtraLight + ".woff") format("woff");
}

// Bold
@font-face {
  font-family: $Bold;
  src: local($Bold), url("../assets/fonts/" + $Bold + ".woff") format("woff");
}

// Medium
@font-face {
  font-family: $Medium;
  src: local($Medium),
    url("../assets/fonts/" + $Medium + ".woff") format("woff");
}

// Regular
@font-face {
  font-family: $Regular;
  src: local($Regular),
    url("../assets/fonts/" + $Regular + ".woff") format("woff");
}

// SemiBold
@font-face {
  font-family: $SemiBold;
  src: local($SemiBold),
    url("../assets/fonts/" + $SemiBold + ".woff") format("woff");
}

// Black
@font-face {
  font-family: $Black;
  src: local($Black), url("../assets/fonts/" + $Black + ".woff") format("woff");
}
